<template>
<div>
  <b-row class="mt-3">
    <b-col md="6" offset-md="6">
      <b-card>
        <b-skeleton width="15%" height="20px"></b-skeleton>

        <b-card-body>
          <div class="flex justify-between align-center" style="">
              <b-skeleton width="70%" height="24px"></b-skeleton>
          </div>

          <b-skeleton width="60%" class="mt-3"></b-skeleton>
          <b-skeleton width="40%"></b-skeleton>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
  <b-row class="mt-3">
    <b-col md="6" v-for="n in Array(4)" v-bind:key="n" class="mb-3">
      <b-card >
        <b-skeleton width="70%" height="32px"></b-skeleton>
        <b-card-body class="pb-0">
          <b-skeleton width="100%" class="mt-1"></b-skeleton>
          <b-skeleton width="100%" class="mt-1"></b-skeleton>
          <b-skeleton width="40%"></b-skeleton>

          <div class="flex align-end">
            <b-skeleton width="80%" height="0" class="mt-3"/>
            <b-skeleton width="20%" height="32px" class="mt-3"/>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</div>

</template>

<script>
export default {
  name: 'CategoryCardSkeleton'
}
</script>
