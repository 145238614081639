<template>
  <div class="home">
    <loading :loading="isLoading"></loading>

    <SectionHeader
    :name="pill.name"
    :description="''"
    :loading="isLoading"
    :has-back-button="true"
    />

    <div class="main-container p-relative">

      <PillDetailSkeleton v-if="isLoading" />

      <b-row class="pill-detail-container pt-5 no-lateral-margin" v-else style="margin-left: 2rem; margin-right: 2rem;">
        <b-col sm="12" md="6" lg="5" offset-md="6" offset-lg="7" class="mb-3">
          <Card no-shadow>
            <template v-slot:title>
              <div class="flex justify-content-between pb-3">
                <div>
                  <StatusPill :status="pillStatus" />
                </div>
              </div>
            </template>
            <template v-slot:content>
              <span class="font-weight-bold" style="font-size: 0.8rem;">{{ $t('PILLS.description') }}</span><br>

              <p v-html="pill.description" />

              <span v-if="hasLessons" class="font-weight-bold" style="font-size: 0.8rem;">{{ $t('PILLS.lessons') }}</span><br>
              <p v-if="hasLessons" v-html="pill.lessons" />

            <b-row class="mt-1">
              <b-col cols="6" sm="6" lg="3" class="mb-2">
                <span class="font-weight-bold" style="font-size: 0.8rem;">{{ $t('PILLS.type') }}</span><br>
                <TypePill :type="pill.type" class="mr-2" />
              </b-col>
              <b-col cols="6" sm="6" lg="3">
                <span class="font-weight-bold" style="font-size: 0.8rem;">{{ $t('PILLS.difficulty') }}</span><br>
                <DifficultyPill :difficulty="pill.difficulty" class="mr-2" />
              </b-col>
              <b-col cols="6" sm="6" lg="3">
                <span class="font-weight-bold" style="font-size: 0.8rem;">{{ $t('PILLS.duration') }}</span><br>
                {{ pill.duration }} {{ pill.duration == '1'? $t('GENERAL.minute') : $t('GENERAL.minutes') }}
              </b-col>
              <b-col cols="6" sm="6" lg="3">
                <span class="font-weight-bold" style="font-size: 0.8rem;">{{ $t('PILLS.points') }}</span><br>
                {{ pill.points }}
              </b-col>
            </b-row>
            <div class="mt-3">
              <span class="font-weight-bold" style="font-size: 0.8rem;">{{ $t('GENERAL.actions') }}</span><br>

              <b-button class="hover-expand mt-1" @click="startPillExecution" variant="secondary" block>
                <font-awesome-icon class="icon mr-2" :icon="['fa', 'play']" style="font-size: 1rem;" />
                {{ goToPillBtnText }}
              </b-button>
            </div>
            </template>
          </Card>
        </b-col>
      </b-row>

      <b-row classs="no-lateral-margin" style="margin-left: 2rem; margin-right: 2rem;">
        <!-- Skills Nice -->
        <b-col sm="12" md="6" lg="6" offset-lg="1" class="mb-5" v-if="hasNiceSkill">
          <Card
            no-shadow
            >
            <template v-slot:title>
              <div class="flex align-items-center mb-3">
                <img src="/media/img/icons/mitre_icon.svg" alt="" style="height: 48px;">
                <h4 class="ml-3 mb-0">{{ $t('TERMS.NICE.skills') }}</h4>
              </div>
            </template>

            <template v-slot:content>
              <ul class="custom">
                <li class="body-2" v-for="obj in filteredSkillsNice" v-bind:key="obj.id">{{ obj.name }}</li>
              </ul>
              <div class="text-right" v-if="skillsNice.length > 3">
                <b-button variant="primary" @click="showMoreSkills = !showMoreSkills">{{ $t(showMoreSkills ? 'GENERAL.show_less' : 'GENERAL.show_more') }}</b-button>
              </div>
            </template>
          </Card>
        </b-col>

        <b-col sm="12" md="6" lg="5" :offset-md="hasNiceSkill ? 0 : 6" :offset-lg="hasNiceSkill ? 0 : 7" class="mb-5">
          <Card
            no-shadow
            >

            <template v-slot:title>
              <div class="flex align-items-center mb-3">
                <img src="/media/img/icons/mitre_icon.svg" alt="" style="height: 48px;">
                <h4 class="ml-3 mb-0">{{ $t('TERMS.techniques_mitre') }}</h4>
              </div>
            </template>

            <template v-slot:content>
              <p>{{ $t('PILLS.DETAIL.techniques_mitre_text') }}.</p>
              <div class=text-right>
                <span class="btn btn-primary" @click="$refs.modal.show()">{{ $t('ACTIONS.show_list') }}</span>
              </div>
            </template>
          </Card>
        </b-col>

        <!-- Knowledge Nice -->
        <b-col sm="12" md="6" lg="6" offset-lg="1" class="mb-5" v-if="hasNiceKnowledges">
          <Card
            no-shadow
            >
            <template v-slot:title>
              <div class="flex align-items-center mb-3">
                <img src="/media/img/icons/mitre_icon.svg" alt="" style="height: 48px;">
                <h4 class="ml-3 mb-0">{{ $t('TERMS.NICE.knowledges') }}</h4>
              </div>
            </template>

            <template v-slot:content>
              <ul class="custom">
                <li class="body-2" v-for="obj in filteredKnowledgeNice" v-bind:key="obj.id">{{ obj.name }}</li>
              </ul>

              <div class="text-right" v-if="knowledgesNice.length > 3">
                <b-button variant="primary" @click="showMoreKnowledges = !showMoreKnowledges">{{ $t(showMoreKnowledges ? 'GENERAL.show_less' : 'GENERAL.show_more') }}</b-button>
              </div>
            </template>
          </Card>

        </b-col>

        <!-- Abilities Nice -->
        <b-col sm="12" md="6" lg="5" class="mb-5" v-if="hasNiceAbilities">
          <Card
            no-shadow
            >
            <template v-slot:title>
              <div class="flex align-items-center mb-3">
                <img src="/media/img/icons/mitre_icon.svg" alt="" style="height: 48px;">
                <h4 class="ml-3 mb-0">{{ $t('TERMS.NICE.abilities') }}</h4>
              </div>
            </template>
            <template v-slot:content>
              <ul class="custom">
                <li class="body-2" v-for="obj in filteredAbilitiesNice" v-bind:key="obj.id">{{ obj.name }}</li>
              </ul>

              <div class="text-right" v-if="abilitiesNice.length > 3">
                <b-button variant="primary" @click="showMoreAbilities = !showMoreAbilities">{{ $t(showMoreAbilities ? 'GENERAL.show_less' : 'GENERAL.show_more') }}</b-button>
              </div>
            </template>
          </Card>
        </b-col>
      </b-row>
    </div>

    <b-modal
      ref="modal"
      :title="$t('TERMS.techniques_mitre')"
      :ok-title="$t('ACTIONS.accept')"
      :cancel-title="$t('ACTIONS.cancel')"
      size="xl"
      dialog-class="w95p max-width-unset"
      hide-footer
    >
        <MitreMatrix :highlight="abilities" />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import SectionHeader from '@/components/SectionHeader'

import DifficultyPill from '@/components/utils/pills/DifficultyPill'
import TypePill from '@/components/utils/pills/TypePill'
import StatusPill from '@/components/utils/pills/StatusPill'

import MitreMatrix from '@/components/WrapperForMitreMatrix'
import PillDetailSkeleton from '@/components/skeletons/PillDetailSkeleton'

import { PILL_STATUS } from '@/config/constants'
import ToastsMixin from '@/services/mixins/toasts.mixins'
import ErrorsMixin from '@/services/mixins/errors.mixins'
import i18nmixin from '@/services/mixins/i18n.mixins'

export default {
  name: 'PillDetail',
  components: { DifficultyPill, TypePill, StatusPill, MitreMatrix, PillDetailSkeleton, SectionHeader },
  mixins: [ToastsMixin, ErrorsMixin, i18nmixin],
  data () {
    return {
      showMoreSkills: false,
      showMoreAbilities: false,
      showMoreKnowledges: false
    }
  },
  methods: {
    ...mapActions({
      getPillDetail: 'pills/getSmallPillDetail',
      startPill: 'pills/startPill'
    }),
    shortened (str) {
      return str && str.length < 50 ? str : str.slice(0, 50).concat('...')
    },
    startPillExecution () {
      this.startPill(this.pill.id)
        .then(_ => { this.goToPillExecution() })
        .catch(_ => { this.showErrorToast(this.$t('PILLS.ERRORS.start_pill')) })
    },
    goToPillExecution () {
      this.$router.push({ name: 'pillExecution', params: { id: this.pill.id || -1 } })
    }
  },
  computed: {
    ...mapGetters({
      originalPill: 'pills/getPillDetail',
      isLoading: 'pills/isLoading'
    }),
    pill () { return this.translateObject(this.originalPill) },
    pillStatus () { return typeof this.pill.student_pill_completion !== 'undefined' ? this.pill.student_pill_completion.completion_status : '' },
    pillHasStatus () { return this.pillStatus !== '' },
    pillIsPending () { return !this.pillHasStatus || PILL_STATUS.PENDING === this.pillStatus },
    pillIsInProgress () { return this.pillHasStatus && PILL_STATUS.IN_PROGRESS === this.pillStatus },
    pillIsInComplete () { return this.pillHasStatus && PILL_STATUS.COMPLETE === this.pillStatus },
    abilities () {
      if (typeof this.pill.abilities_mitre === 'undefined') return []
      return this.pill.abilities_mitre.map(item => item.id)
    },

    goToPillBtnText () {
      if (this.pillIsPending) {
        return this.$t('ACTIONS.start')
      } else if (this.pillIsInProgress) {
        return this.$t('ACTIONS.continue')
      } else if (this.pillIsInComplete) {
        return this.$t('ACTIONS.do_again')
      } else {
        return this.$t('ACTIONS.start')
      }
    },

    isRelatedToAWorkRole () { return typeof this.pill.work_roles_nice !== 'undefined' && this.pill.work_roles_nice.length > 0 },
    isRelatedToAObjective () { return typeof this.pill.objectives !== 'undefined' && this.pill.objectives.length > 0 },
    hasNiceSkill () { return typeof this.pill.skills_nice !== 'undefined' && this.pill.skills_nice.length > 0 },
    hasNiceAbilities () { return typeof this.pill.abilities_nice !== 'undefined' && this.pill.abilities_nice.length > 0 },
    hasNiceKnowledges () { return typeof this.pill.knowledges_nice !== 'undefined' && this.pill.knowledges_nice.length > 0 },

    hasLessons () { return typeof this.pill.lessons !== 'undefined' && this.pill.lessons.length > 0 },

    skillsNice () { return this.hasNiceSkill ? this.pill.skills_nice : [] },
    abilitiesNice () { return this.hasNiceAbilities ? this.pill.abilities_nice : [] },
    knowledgesNice () { return this.hasNiceKnowledges ? this.pill.knowledges_nice : [] },

    filteredSkillsNice () { return this.showMoreSkills ? this.skillsNice : this.skillsNice.slice(0, 3) },
    filteredAbilitiesNice () { return this.showMoreAbilities ? this.abilitiesNice : this.abilitiesNice.slice(0, 3) },
    filteredKnowledgeNice () { return this.showMoreKnowledges ? this.knowledgesNice : this.knowledgesNice.slice(0, 3) }
  },
  mounted () {
    this.getPillDetail(this.$route.params.id)
      .catch(this.handleAxiosError)
  }
}
</script>

<style lang="scss" scoped>

.pill-detail-container {

  &:before {
    content: '';
    display: block;
    height: 430px;
    background-image: url('/media/img/bg/banner_pill.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.objective {
  border-bottom: 1px solid $grey;
}

@media (max-width: $mobile-breakpoint) {
  .banner {
    margin: 1rem 0rem;
  }
}

ul.custom li {
  margin-bottom: 0.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>
